import request from '../utils/request'
import qs from "qs";
import {encrypt} from "../utils/rsautil" //RSA加密类
//import qs from 'qs'
/**
 * 保存居民过户
 * @param param
 * @returns {*}
 */
export function saveAccountTransfer(param){
    /*var configs = {
        headers:{'Content-Type':'multipart/form-data'}
    }*/
    //request.default.headers = {'Content-Type':'application/json'}
    param.attachs = param.attachs.toString()
    param.attachs1 = param.attachs1.toString()
    param.attachs2 = param.attachs2.toString()
    param.isCheckAddress = param.isCheckAddress ? 1 : 0;
    param['postType'] = 'application/json'
    return request({
        url: 'transfer/saveAccountTransfer.do',
        method: 'post',
        data: param
    })
}

/**
 * 根据手机号码查询实名认证情况
 * @param mobile
 */
export function queryPersonalMobile(mobile){
    return request({
        url: 'transfer/queryPersonalMobile.do',
        method: 'post',
        data: qs.stringify({phone: encrypt(mobile)})
    })
}