<template>
  <van-overlay style="z-index: 19999" :show="show" @click="show = false">
    <div class="pop" @click="close">
      <div class="title">{{title}}</div>
      <div class="content" v-html="content"></div>
      <div CLASS="tools" @click="close">
        关闭
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "PopUploadDemo",
  props:{
    show:{},
    title:{},
    content:{}
  },
  data () {
    return{
      curShow: this.show
    }
  },
  methods:{
    close(){
      this.$emit('popClose')
    }
  }
}
</script>

<style scoped>
.pop{width: 95vw;height: 95vh; background: white; margin: 2.5vw;z-index: 19999;overflow: hidden;}
.pop .title{height: 6vh;line-height: 6vh;font-weight: bold;text-align: center; border-bottom: lightgrey solid 1px;}
.pop .content{padding: 5px; width: 100%;height: 83vh;overflow-y: scroll;}
.pop .tools{height: 6vh;line-height: 6vh;border-top: lightgrey solid 1px;text-align: center}
</style>